import {Component, Prop} from 'vue-property-decorator';
import {BaseScript} from '@/components/BaseScript';

@Component({
    name: 'ImageViewer',
})
export default class ImageViewer extends BaseScript {
    @Prop(String) private readonly customModalId!: string;
    @Prop(String) private readonly imageSrc!: string;
    @Prop(String) private readonly imageAlt!: string;

    constructor() {
        super();
    }
}
