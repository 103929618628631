import { Component } from 'vue-property-decorator';
import {BaseScript} from '@/components/BaseScript';


// @ts-ignore
@Component({
    name: 'TopButton',
})
export default class TopButton extends BaseScript {
    constructor() {
        super();
    }
}
