import {Component, Prop, Watch} from 'vue-property-decorator';
import {BaseScript} from '@/components/BaseScript';

// @ts-ignore
@Component({
    name: 'PercentageCircle',
})
export default class PercentageCircle extends BaseScript {
    // props
    @Prop(Number) private readonly percentage!: number;
    @Prop(Number) private readonly toWrite!: number;

    constructor() {
        super();
    }

    /**
     * Récupére la class lié au pourcentage
     *
     * @private
     * @return {string}
     */
    private get preparePercentage(): string {
        return this.percentage >= 0 ? 'p' + this.percentage : '';
    }

    /**
     * Récupération de la note à afficher
     *
     * @private
     * @return {string}
     */
    private get writeToShow(): string {
        return Number(this.toWrite) < 0 ? '-' : String(this.toWrite);
    }

    /**
     * Choisi la couleur à afficher en fonction de la note
     *
     * @private
     * @return {string}
     */
    private get prepareColorClass(): string {
        if (this.percentage >= 0 && this.percentage <= 45) {
            return  'red';
        } else if (this.percentage > 45 && this.percentage < 70) {
            return  'orange';
        } else if (this.percentage >= 70 && this.percentage <= 100) {
            return  'green';
        } else {
            return  '';
        }
    }
}
