/**
 * Cette class est utilisé pour récupérer les statistiques affichées
 * dans l'encadrement "Informations" de chaque catégorie.
 */
import Common from '@/services/Common';

export class InfosCollection {
    private _itemsList: any;

    constructor(itemsList: any = []) {
        this._itemsList = itemsList;
    }

    /**
     * @param any[] value
     */
    set itemsList(value: any[]) {
        if (value === this._itemsList) {
            return;
        }

        this._itemsList = value;
    }

    /**
     * Retourne la taille d'une liste d'items
     *
     * @public
     * @return {string|number}
     */
    public listLength(): string|number {
        if (Array.isArray(this._itemsList)) {
            return this._itemsList.length;
        } else {
            return '0';
        }
    }

    /**
     * Récupére le nombre d'item se situant dans notre collection.
     * (hors état "Emprunté" et "Vendu")
     *
     * @public
     * @return {number}
     */
    public getNbrOfSeriesCollection(): number {
        if (Array.isArray(this._itemsList)) {
            // on récupére tous les items ayant l'état collection
            const collectionList = this._itemsList.filter((item: any) => {
                if (item.etat !== 'Emprunté' && item.etat !== 'Vendu') {
                    return item;
                }
            });
            return collectionList.length;
        } else {
            return 0;
        }

    }

    /**
     * Retourne le nombre de tome sur l'intégralité des série.
     *
     * @public
     * @return {number}
     */
    public getNbrOfTome(): number {
        let nbrTomeTotal = 0;
        if (this._checkIsLivre()) {
            this._itemsList.forEach((item: any) => {
                nbrTomeTotal += item.tomes.nbrTome;
            });
        }
        return nbrTomeTotal;
    }

    /**
     * Retourne le nombre de tome sur l'intégralité des séries qui sont dans la collection.
     * (hors état "Emprunté" et "Vendu")
     *
     * @public
     * @return {number}
     */
    public getNbrOfTomeCollection(): number {
        let nbrTomeTotal = 0;
        if (this._checkIsLivre()) {
            this._itemsList.forEach((item: any) => {
                if (item.etat !== 'Emprunté' && item.etat !== 'Vendu') {
                    nbrTomeTotal += item.tomes.nbrTome;
                }
            });
        }
        return nbrTomeTotal;
    }

    /**
     * Récupére le nombre de tomes total lu avec son pourcentage par rapport à toute la collection
     *
     * @public
     * @return {string}
     */
    public getNbrOfTomeLu(): string {
        if (this._checkIsLivre()) {
            let nbrTomeTotal = 0;
            this._itemsList.forEach((item: any) => {
                nbrTomeTotal += item.tomes.nbrTomeLu;
            });

            let percent = Math.round((100 * nbrTomeTotal) / this.getNbrOfTome());
            percent = !isFinite(percent) ? 0 : percent;

            return '(' + percent + '%) ' + nbrTomeTotal;
        } else {
            return '(0%) 0';
        }
    }


    /**
     * Retourne le nombre de jeux fini.
     *
     * @public
     * @return {string}
     */
    public getNbrDone(): string {
        const collectionList = this._itemsList.filter((item: any) => {
            if (item.statut === 'Terminé') {
                return item;
            }
        });

        let percent = Math.round((100 * collectionList.length) / this._itemsList.filter((item: any) => {
            if (item.statut !== 'Sans fin') {
                return item;
            }
        }).length);
        percent = !isFinite(percent) ? 0 : percent;

        return '(' + percent + '%) ' + collectionList.length;
    }

    /**
     * Vérifie qu'il s'agit bien d'une catégorie livre
     * @private
     * @return {boolean}
     */
    private _checkIsLivre(): boolean {
        return Array.isArray(this._itemsList) && this._itemsList.length > 0 && Common.checkIsNotUndifined(this._itemsList[0].tomes);
    }
}
